import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocaleDate } from '../../utils/date';
import OffsetBackground from '../OffsetBackground/OffsetBackground';
import './FixtureGrid.scss';
import SpinnerButton from '../SpinnerButton/SpinnerButton';
import TeamLogo from '../TeamLogo/TeamLogo';
import { fixturesProps, fixtureSummary } from '../../utils/customPropTypes';
import Chevron from '../Icons/Chevron';
import Skeleton from '../Skeleton/Skeleton';
import { trackEvent, eventTypes } from '../../utils/tracking';

const FixtureGrid = ({
  fixtures,
  loadMore,
  loading,
  loadingPage,
  apiKey,
  theme,
  buttonText,
  onFixtureClick,
  containerClasses,
}) => {
  const { t } = useTranslation();

  const { mainBrand, background } = theme || {};

  const fixtureCard = fix => (
    <FixtureCard
      key={fix?.id}
      fixture={fix}
      mainBrand={mainBrand}
      buttonText={buttonText}
      onFixtureClick={onFixtureClick}
    />
  );

  const fixturesComponent =
    fixtures.length > 0 ? (
      <OffsetBackground
        additionalStyle={{ background }}
        offset={100}
        className="pb-10 lg:pb-16"
        innerClass={containerClasses}
      >
        <div className="fixture_container">{fixtures.map(fixtureCard)}</div>
        {loadMore && (
          <div className="flex justify-center pt-6 lg:pt-12">
            <SpinnerButton
              text={t('general_seeMore')}
              onClick={() => {
                trackEvent(eventTypes.seeMoreFixtures, apiKey);
                loadMore();
              }}
              loading={loadingPage}
              buttonStyle="font-semibold text-base mx-6 rounded-lg bg-white w-full p-3.5 lg:w-3/4 md:mt-2"
              width={500}
            />
          </div>
        )}
      </OffsetBackground>
    ) : (
      <div className="flex w-full justify-center border-t border-lightGrey px-24 py-16 md:py-36">
        <p className="break-words text-center text-xl font-bold">{t('venue_noFixtures')}</p>
      </div>
    );

  return (
    <div className="w-full">
      {loading ? (
        <OffsetBackground offset={100} className="pb-10" innerClass="containerA mx-auto">
          <div className="fixture_container mb-16 lg:pb-6">
            {[...Array(8).keys()].map(num => (
              <Skeleton
                key={num}
                stylesString="h-[260px] md:h-[280px] lg:h-[334px] fixture_tiles"
              />
            ))}
          </div>
        </OffsetBackground>
      ) : (
        fixturesComponent
      )}
    </div>
  );
};

export const FixtureCard = ({ fixture, onFixtureClick, mainBrand, buttonText }) => {
  const { t } = useTranslation();
  const getLocaleDate = useLocaleDate();
  const { teams, competition, venueEvent, id, name, schedule } = fixture || {};
  const oneTeamEvent = !teams?.home && !teams?.away;

  const teamComponent = (text, image, single = false) => (
    <div className={single ? 'fixture_singleTeam' : 'fixture_team'}>
      <TeamLogo size={24} image={image} className="flex h-12 w-12 items-center bg-white p-2" />
      <p className="text-sm">{text}</p>
    </div>
  );

  return (
    <button disabled={!onFixtureClick} type="button" onClick={() => onFixtureClick(id)}>
      <div className="fixture_tiles">
        <div>
          <p className="truncate bg-veryLightGrey p-2 text-left text-xs uppercase text-black lg:px-4 lg:py-3">
            {competition?.competitionOverride || competition?.name}
          </p>
          <div className="flex items-center justify-between bg-offWhite p-2 lg:px-4 lg:py-3">
            <p className="text-xs uppercase opacity-40">
              {getLocaleDate(schedule?.start, 'd MMMM')}
            </p>
            <p className="rounded-full bg-black p-0.5 px-2 text-[10px] font-semibold text-white lg:text-sm">
              {getLocaleDate(schedule?.start, 'HH:mm')}
            </p>
          </div>
        </div>
        {oneTeamEvent ? (
          teamComponent(name, competition?.logo, true)
        ) : (
          <>
            {teamComponent(teams?.home?.name, teams?.home?.logo)}
            {teamComponent(teams?.away?.name, teams?.away?.logo)}
          </>
        )}
        <div className="fixture_bottom">
          {onFixtureClick && (
            <div style={{ backgroundColor: mainBrand }} className="fixture_button">
              <p className="line-clamp-1 flex-1  text-xs">{buttonText}</p>
              <Chevron className="rotate-90" size={16} />
            </div>
          )}
          {venueEvent && (
            <div className="flex w-1/2 md:w-auto">
              <p className={`fixture_footer ${venueEvent?.sound ? 'text-[#00C89C]' : ''}`}>
                {t(venueEvent?.sound ? 'fixture_soundOn' : 'fixture_soundOff')}
              </p>
            </div>
          )}
        </div>
      </div>
    </button>
  );
};

FixtureGrid.propTypes = {
  fixtures: fixturesProps,
  loadMore: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  loadingPage: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  apiKey: PropTypes.string.isRequired,
  theme: PropTypes.shape(),
  buttonText: PropTypes.string.isRequired,
  onFixtureClick: PropTypes.func,
  containerClasses: PropTypes.string.isRequired,
};

FixtureGrid.defaultProps = {
  loadMore: null,
  fixtures: [],
  onFixtureClick: () => {},
  theme: {},
};

FixtureCard.propTypes = {
  fixture: fixtureSummary,
  onFixtureClick: PropTypes.func,
  mainBrand: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
};

FixtureCard.defaultProps = {
  fixture: {},
  onFixtureClick: () => {},
  mainBrand: null,
};

export default FixtureGrid;
