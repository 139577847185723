import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from '../../components/PageHeader/PageHeader';
import Venues from '../../components/VenueList/VenueList';
import { themeProps } from '../../utils/customPropTypes';
import UEFAHeader from './UEFAHeader/UEFAHeader';

const VenueList = ({ theme, apiKey }) => (
  <>
    <PageHeader theme={theme} />
    <UEFAHeader apiKey={apiKey} />
    <Venues theme={theme} apiKey={apiKey} loadUpcomingFixture />
  </>
);

VenueList.propTypes = {
  theme: themeProps,
  apiKey: PropTypes.string.isRequired,
};

VenueList.defaultProps = {
  theme: undefined,
};

export default VenueList;
