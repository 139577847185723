/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Modal = ({ children, open, onClose }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  return (
    open && (
      <>
        <div className="lg:containerA fixed inset-0 z-20 overflow-y-auto rounded-xl sm:inset-8 lg:mx-auto lg:px-0">
          {children}
        </div>
        <div onClick={onClose} className="absolute inset-0 z-[9] bg-[#000]/60" />
      </>
    )
  );
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Modal;
