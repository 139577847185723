import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from './UEFAHeader.module.scss';
import { CDNURL } from '../../../utils/general';

const UEFAHeader = ({ apiKey }) => {
  const { t } = useTranslation();

  if (apiKey !== 'a8319f25-5408-47c4-8d27-2c0a9b75082f') {
    return null;
  }

  return (
    <div className="bg-[#143CDB]">
      <div className="lg:containerA flex flex-col-reverse justify-between gap-2 p-4 pt-6 text-white md:flex-row md:p-6 lg:mx-auto">
        <div>
          <h1 className={styles.title}>{t('uefaHeader_title')}</h1>
          <h3 className={styles.subtitle}>{t('uefaHeader_subtitle')}</h3>
        </div>
        <div className="flex flex-col items-center justify-center self-end md:self-auto lg:flex-row">
          <p className={`${styles.baseText} text-right text-[10px] lg:mr-3`}>{t('presented_by')}</p>
          <img src={`${CDNURL}venus/uefa/bitburger.png`} alt="" className="h-[39px]" />
        </div>
      </div>
    </div>
  );
};

UEFAHeader.propTypes = {
  apiKey: PropTypes.string.isRequired,
};

export default UEFAHeader;
